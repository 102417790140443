import React, { useContext } from "react";
import { appIcon, appPicture } from "../utils/images";

import { AiFillAndroid } from "react-icons/ai";
import { FaApple } from "react-icons/fa";
import ModalContext from "../utils/ModalContext";

function PreRegisterSection() {
  const { setToggleModal } = useContext(ModalContext);
  return (
    <section className="appSection">
      <div className="container">
        <div className="row">
          <div className="col-md-6 my-auto">
            <div className="appBanner">
              <h4>Download SIKAMA</h4>
              <p>
                Stay informed about the latest industry trends, product updates,
                and company news. With the Sikama app, you can access engaging
                webinars, workshops, and conferences that are designed to
                enhance your knowledge and experience with Sikama's cutting-edge
                solutions.
              </p>
              {/* <p>
                Pre-register for Sikama International's upcoming events and stay
                informed about the latest industry trends, product updates, and
                company news. Don't miss out on our engaging webinars,
                workshops, and conferences designed to enhance your knowledge
                and experience with Sikama's cutting-edge solutions.
              </p> */}
              <div style={{ display: "flex", columnGap: 20 }}>
                <button>
                  {/* <img alt="" src={appIcon} /> */}
                  <FaApple size={40} color="#fff" style={{ marginRight: 6 }} />
                  <h5>
                    Download <span>iOS</span>
                  </h5>
                </button>
                <button>
                  <AiFillAndroid
                    size={40}
                    color="#fff"
                    style={{ marginRight: 6 }}
                  />

                  <h5>
                    Download <span>Android</span>
                  </h5>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="appPicture">
              <img alt="" src={appPicture} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreRegisterSection;
