import "swiper/css";

import React, { useContext, useEffect, useRef } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  arrowLeft,
  arrowRight,
  discover1,
  discover2,
  discover3,
  discover4,
  discover5,
  discover6,
  discoverBanner,
  howBanner,
  howSection,
  paymentOption,
  sikamaBanner,
  user,
} from "../utils/images";
import { chooseData, howContent, testimonialData } from "../utils/appData";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import ComingSoon from "../components/ComingSoon";
import { Link } from "react-router-dom";
import ModalContext from "../utils/ModalContext";
import PreRegisterSection from "../components/PreRegisterSection";
import { ReactSVG } from "react-svg";
import blogContent from "../utils/blogContent";
import scrollTop from "../utils/scrollTop";

function HomePage() {
  const swiper = useSwiper();
  const swiperRef = useRef();
  const swiperRef2 = useRef();
  const { setToggleModal } = useContext(ModalContext);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <main>
      <AppHeader />

      <section className="sectionPadding section-80vh">
        <div className="leftCircle"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-5 my-auto">
              <div className="bannerContent">
                <h2>
                  <strong>SIKAMA</strong> Makes Your Transactions Very Easier
                </h2>
                <p>
                  Our passion to work hard and deliver excellent results. It
                  could solve the needs of your customers and develop
                  innovation.
                </p>
                <div className="bannerBtns">
                  <Link
                    onClick={() => setToggleModal(true)}
                    className="btnRegister"
                  >
                    sign up
                  </Link>
                  <Link to="/about" className="heroBtn2">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="bannerImg">
                <img
                  src={sikamaBanner}
                  className="appLogo"
                  width={300}
                  height={300}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container">
          <marquee direction="left">
            <div className="sponsorImgs">
              <img alt="" src={paymentOption} width={80} height={30} />
            </div>
          </marquee>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container">
          <div className="headingWrapper">
            <h4 className="mainHeading">Why Choose SIKAMA</h4>
            <p className="mainSubheading">
              Our main goal is to offer trustworthy money transmitter services
              at affordable prices. Along with this objective, some of our
              objectives are discussed below:
            </p>
          </div>
          <div className="row">
            {chooseData.map((item, index) => (
              <div className="col-md-3">
                <div
                  className={
                    index == 0
                      ? "singleChooseCard colorSvg"
                      : "singleChooseCard"
                  }
                >
                  {index == 3 ? (
                    <div className="lastSvg">
                      <ReactSVG src={item.icon} />
                    </div>
                  ) : (
                    <ReactSVG src={item.icon} />
                  )}

                  <h4>{item.heading}</h4>
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="howSection" style={{ backgroundImage: howSection }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={howBanner}
                className="bannerLeft"
                width={300}
                height={300}
              />
            </div>
            <div className="col-md-6">
              <div className="howContent">
                <h4>How App Works</h4>
                <p>
                  Sikama App is software remittance application that allows
                  customers to chat and seamlessly send and receive funds from
                  their family, friend, and organizations.
                </p>
                {howContent.map((item) => (
                  <div className="pointContainer">
                    <img alt="" src={item.icon} width={80} height={30} />
                    <div className="pointTxt">
                      <h6>{item.title}</h6>
                      <p>{item.para}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container">
          <div className="headingWrapper">
            <h4 className="mainHeading">More to Discover</h4>
            <p className="mainSubheading">
              Uncover a world of possibilities with Sikama's diverse range of
              products and services. From cutting-edge technology to
              unparalleled customer support, there's always more to discover.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 my-auto text-right mobileReverse">
              <div className="discoverContent">
                <div className="content">
                  <h4>Ads & Promotions</h4>
                  <p>
                    Sikama uses Ads and Promotions to reach the market audience
                    for our app.
                  </p>
                </div>
                <img alt="" src={discover1} width={80} height={30} />
              </div>
              <div className="discoverContent">
                <div className="content">
                  <h4>Fully Functional</h4>
                  <p>
                    Sikama is a fully functional and user-friendly application
                    with our expert tips and best practices for seamless
                    navigation and optimal performance.
                  </p>
                </div>
                <img alt="" src={discover2} width={80} height={30} />
              </div>
              <div className="discoverContent">
                <div className="content">
                  <h4>Chat with Contacts</h4>
                  <p>
                    To chat with your contacts, utilize Sikama messaging app to
                    stay connected and communicate seamlessly.
                  </p>
                </div>
                <img alt="" src={discover3} width={80} height={30} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="discoverPicture">
                <img alt="" src={discoverBanner} width={80} height={30} />
              </div>
            </div>
            <div className="col-md-4 my-auto">
              <div className="discoverContent">
                <img alt="" src={discover4} width={80} height={30} />
                <div className="content">
                  <h4>History Management</h4>
                  <p>
                    History management help users to view and review their past
                    transaction.
                  </p>
                </div>
              </div>
              <div className="discoverContent">
                <img alt="" src={discover5} width={80} height={30} />
                <div className="content">
                  <h4>Simple and Interactive</h4>
                  <p>
                    Sikama App is an interactive tool for users to engage and
                    provide an enjoyable browsing experience, incorporating
                    modern design elements and interactive features.
                  </p>
                </div>
              </div>
              <div className="discoverContent">
                <img alt="" src={discover6} width={80} height={30} />
                <div className="content">
                  <h4>Fast and Secure</h4>
                  <p>
                    Enhanced website speed and security are our goals to ensure
                    the privacy and security of our customers’ information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container">
          <div className="headingWrapper">
            <h4 className="mainHeading">Our Latest Blogs</h4>
            <p className="mainSubheading">
              Explore our latest blog posts for insightful articles, industry
              trends, and expert tips to stay informed and inspired.
            </p>
          </div>

          <Swiper
            spaceBetween={20}
            loop={true}
            onSwiper={(swiper) => {
              swiperRef2.current = swiper;
            }}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {blogContent.map((item) => (
              <SwiperSlide>
                <div className="singleBlogCard">
                  <Link
                    className="absoluteLink"
                    to={`/blog_detail?blog=${item.blogTitle
                      .split(" ")
                      .join("-")}`}
                  />
                  <img
                    className="blogImg"
                    src={item.blogBanner}
                    width={300}
                    height={200}
                  />
                  <div className="blogContent">
                    {/* <div className="blogInfo">
                      <span>
                        <img alt="" src={user} width={80} height={30} />
                        Admin
                      </span>
                      <span>
                        <img alt="" src={clockIcon} width={80} height={30} />
                        January 14, 2021
                      </span>
                    </div> */}
                    <h4>{item.blogTitle.split(" ").slice(0, 6).join(" ")}</h4>
                    <p>{item.para.split(" ").slice(0, 20).join(" ")}</p>
                    <span className="readMore">Read More</span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="sliderBtn">
              <button
                className="btn"
                onClick={() => swiperRef2.current.slidePrev()}
              >
                <img alt="" src={arrowLeft} />
              </button>
              <button
                className="btn"
                onClick={() => swiperRef2.current.slideNext()}
              >
                <img alt="" src={arrowRight} />
              </button>
            </div>
          </Swiper>
        </div>
      </section>

      {/* <ComingSoon title="Our Blog" /> */}

      <section className="sectionPadding testimonialSection">
        <div className="container">
          <div className="headingWrapper">
            <h4 className="mainHeading">Testimonials</h4>
            <p className="mainSubheading">
              Read what our satisfied customers have to say about their
              experience with Sikama International. From industry leaders to
              small businesses, our products and services have helped countless
              customers achieve their goals.
            </p>
          </div>

          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={20}
            centeredSlides={true}
            loop={true}
          >
            {testimonialData.map((item) => (
              <SwiperSlide key={item.name}>
                <div className="testimonialCard">
                  <div className="profilePic">
                    <img alt="" src={item.profile} />
                  </div>
                  <h4>{item.name}</h4>
                  <span>{item.designation}</span>
                  <p>{item.desc}</p>
                </div>
              </SwiperSlide>
            ))}

            <div className="sliderBtn testimonialSliderBtn">
              <button
                className="btn"
                onClick={() => swiperRef.current.slidePrev()}
              >
                <img alt="" src={arrowLeft} />
              </button>
              <button
                className="btn"
                onClick={() => swiperRef.current.slideNext()}
              >
                <img alt="" src={arrowRight} />
              </button>
            </div>
          </Swiper>
        </div>
      </section>

      <section className="sectionPadding mt-5 mobileNone"></section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default HomePage;
